import React from 'react';
// interfaces
import { IImage } from '../../interfaces/image';
// components
import AddToBasket from '../add-to-basket';
import Image from '@components/image/image';
// styles
import styles from './hero-nutrition-test.module.scss';

interface IProps {
  title: string;
  description: string;
  image: IImage;
  phoneImage: IImage;
  phoneContentImage: IImage;
  phoneTabletImage: IImage;
  plans: any;
}

const HeroNutritionTest: React.FC<IProps> = ({
  title,
  description,
  image,
  phoneImage,
  phoneContentImage,
  phoneTabletImage,
  plans,
}: IProps) => (
  <section className={styles.section}>
    <div className={styles.imageHalf}>
      <div className={styles.desktopImage}>
        <Image filename={image.filename_disk} alt={image.title} width={800} hideOnTablet hideOnMobile />
      </div>
      <div className={styles.tabletImage}>
        <Image
          filename={phoneTabletImage.filename_disk}
          alt={phoneTabletImage.title}
          width={708}
          hideOnMobile
          hideOnDesktop
        />
      </div>

      <div className={styles.phoneWrapper}>
        <div className={styles.phoneScreen}>
          <Image filename={phoneContentImage.filename_disk} alt={phoneContentImage.title} width={232} />
        </div>
        <Image filename={phoneImage.filename_disk} alt={phoneImage.title} width={534} />
      </div>
    </div>

    <div className={styles.contentHalf}>
      <div className={styles.container}>
        <div className={styles.rowCentered}>
          <div className={styles.content}>
            <h1>{title}</h1>
            <hr className={styles.hrLeftAligned} />
            <p>{description}</p>
            {plans && plans.length > 0 && <AddToBasket plans={plans} />}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HeroNutritionTest;
